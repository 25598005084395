html,
body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  margin: 0;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
}

@font-face {
  font-family: "yg-jalnan";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_four@1.2/JalnanOTF00.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}
.App {
  text-align: center;
}

.App-header {
  background-color: #ffffff;
  min-height: 5vmin;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.subject {
  font-family: "yg-jalnan" !important;
  background-color: #ff4b1f;
  min-height: max(calc(15px + 10vmin), 60px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: max(calc(15px + 2vmin), 25px);
  color: white;
}

.map_ {
  background-color: #ffffff;
  margin: auto;
  margin-top: 10vh;
  position: relative;
  width: 80%;
  overflow: hidden;
}

.map_ .mapimg {
  width: 100%;
  height: auto;
  display: block;
  opacity: 0.8;
}
.map_ .marker_ {
  position: absolute;

  height: 50px;
  font-size: 25px;
  font-weight: bold;
  border-radius: 10px;
  color: black;
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
}
@keyframes highlight {
  100% {
    transform: scale(5);
    opacity: 0;
  }
}
.map_ #marker1 {
  top: 58%;
  left: 31%;
}
.map_ #marker2 {
  left: 45%;
  top: 34%;
}
.map_ #marker3 {
  left: 6%;
  top: 30%;
}
.map_ #marker4 {
  left: 45%;
  top: 75%;
}
.map_ #marker5 {
  left: 78%;
  top: 32%;
}

.map_ .marker_:hover {
  opacity: 1;
}

.flightImg {
  display: "inline-block";
  vertical-align: "middle";
  margin-right: 10px;
}

@media screen and (max-width: 940px) {
  .map_ {
    width: 90%;
    margin-top: 5vh;
  }
  .map_ .mapimg {
    position: inherit;
  }
  .map_ .marker_ {
    margin-top: 2vh;
    opacity: 1;
    font-size: 22px;
    position: inherit;
    justify-content: left;
  }
  a:hover,
  a:visited,
  a:link,
  a:active {
    text-decoration: none !important;
  }
  .flightImg {
    width: 20px;
    margin-right: 20px;
  }
}

.footer {
  position: relative;
}
.App-footer {
  background-color: #d9d9d9;
  width: 100%;
  min-height: 5vh;
  bottom: 0px;
  position: absolute;
}

.css-dasnyc-MuiImageListItemBar-title {
  font-size: 19px !important;
  margin-top: 5px;
  margin-bottom: 5px;
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}
body {
  top: 0px !important;
}
body > .skiptranslate {
  display: none;
}

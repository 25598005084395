.content {
  position: table-cell;
  vertical-align: middle;
  margin-top: 5vmin;
  margin-left: 10vw;
  margin-right: 10vw;
}

.content .checklist1 {
  text-align: left;
  position: relative;
  width: 100%;
  padding-left: 10vmin;
  padding-right: 10vmin;
  /* width:800px; */
  margin-bottom: 5vmin;
  margin-top: 12px;
  margin-left: -8px;
}

.additional {
  margin-left: 10vmin;
  margin-right: 10vmin;
  margin-bottom: 1vmin;
}

.title1 {
  font-size: 30px;
  font-weight: bold;
}

.shareMethod {
  margin-bottom: 30px;
  margin-top: 30px;
  margin-right: 10vmin;
  text-align: right;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.checked-item {
  text-decoration: line-through;
}

input[type="checkbox"]:checked + label.strikethrough {
  text-decoration: line-through;
}

@media screen and (max-width: 1064px) {
  .content .checklist1 {
    position: relative;
    width: 100%;
    padding-left: 5vmin;
    padding-right: 5vmin;
  }

  .additional {
    margin-left: 5vmin;
    margin-right: 5vmin;
  }

  .shareMethod {
    margin-right: 5vmin;
  }
}

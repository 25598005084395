.content {
  position: table-cell;
  vertical-align: middle;
  margin-left: 10vw;
  margin-right: 10vw;
}
.content .name {
  margin-top: 5vh;
  margin-bottom: 2vh;
  font-size: 20px;
  font-weight: bold;
}

.content .checklist {
  padding-left: 10vmin;
  padding-right: 10vmin;
  margin-top: 2vh;
}

.guidelist {
  object-fit: fill;
}
.countryName_ {
  position: absolute;
  font-weight: bold;
  font-size: 45px;
}
@media screen and (max-width: 1064px) {
  .content .checklist {
    padding-left: 5vw;
    padding-right: 5vw;
  }
  .countryName_ {
    font-size: 25px;
  }
}

a:hover,
a:visited,
a:link,
a:active {
  text-decoration: none !important;
}

.content {
  position: table-cell;
  vertical-align: middle;
  margin-top: 5vmin;
  margin-left: 10vw;
  margin-right: 10vw;
}
.content .name {
  margin-top: 5vh;
  margin-bottom: 2vmin;
  padding-left: 10vmin;
  font-size: calc(15px + 1vmin);
  font-weight: bold;
}
.info1 {
  margin-left: 12vmin;
  margin-right: 12vmin;
  margin-top: 2vmin;
  margin-bottom: 2vmin;
}
.content .checklist1 {
  text-align: center;
  padding-left: 10vmin;
  padding-right: 10vmin;
  /* width:800px; */
  margin-bottom: 5vmin;
}

.content .checklist2 {
  text-align: center;
  padding-left: 10vmin;
  padding-right: 10vmin;
  margin-top: 20px;
}
.countryName {
  position: absolute;
  font-weight: bold;
  font-size: 45px;
  top: 20%;
}

@media screen and (max-width: 1064px) {
  .info1 {
    margin-left: 5vmin;
    margin-right: 5vmin;
    margin-top: 2vmin;
    margin-bottom: 2vmin;
  }
  .content .checklist1 {
    position: relative;
    width: 100%;
    padding-left: 5vmin;
    padding-right: 5vmin;
  }
  .content .checklist2 {
    padding-left: 5vmin;
    padding-right: 5vmin;
  }

  .content .name {
    padding-left: 5vmin;
  }
  .countryName {
    font-size: 25px;
  }
}
